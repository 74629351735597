/* General Styles */
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Handlee', 'Patrick Hand', 'Coming Soon', 'Open Sans', 'sans-serif', cursive;
}

.App {
  font-family: 'Handlee','Patrick Hand', 'Coming Soon', 'Open Sans', 'sans-serif',  cursive; 
}

.App a:hover {
  color: unset;
}

.main-page a {
  color: unset;
  background-color: #EAECEE;
}

.nav-link {
  color: rgba(0, 0, 0, 0.5);
}

.social-accounts a {
  color: unset;
  background-color: unset;
}

.footer {
  background-color: #EAECEE; /* Match the background color of other components */
  padding: 20px 0;
  border-top: 2px solid #ABB2B9; /* Match the border color of other components */
  font-family: 'Handlee', 'Patrick Hand', 'Coming Soon', 'Open Sans', 'sans-serif', cursive; /* Match the font family */
}

.main-page h1 {
  font-size: 3em;
}

.main-page h3 {
  font-size: 2em;
}

p {
  font-size: 1.5em;
}

img {
  filter: grayscale(100%);
  height: 230px;
  max-width: 100%; /* Ensure images don't overflow on small screens */
}

.center {
  justify-content: center;
}

#news-card {
  margin-block-end: 19px;
}

img:hover {
  filter: grayscale(100%) brightness(70%);
}

.main-page button {
  margin-inline-end: 34px;
}

.fa, .fab, .fas, .far {
  font-size: 3em;
  color: #ABB2B9;
}

.icons {
  display: inline;
  margin: 1.2rem;
}

.testing-tool-icons {
  width: 130px;
  height: 50px;
}

.testing-tool-icon {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.skills {
  margin-top: 44px;
}

.skills h5 {
  margin-bottom: 17px;
}

.languages {
  margin-bottom: 33px;
}

.main-page span {
  margin: auto;
  width: 100;
  padding: 2%;
}

.buttons {
  border-inline-end: #ABB2B9 solid 2px;
  border-radius: 50%;
  height: 30rem;
}

.buttons button {
  width: 100px;
  height: 100px;
  transform: rotate(45deg);
  border-radius: 0% 80%;
  margin-block-start: 50%;
}

#about-me,
.messageStatus .card {
  border: none;
}

#profile-photo {
  width: 70%;
  height: auto;
  box-shadow: #ABB2B9 5px 21px 37px 19px;
  border-radius: 12px;
  margin-bottom: 37px;
}

#profile-photo:hover {
  filter: none;
}

.social-accounts {
  margin-top: 25px;
}

/* Contact Page */
#contactPage {
  width: 80%;
  margin: auto;
}

#contactPage button {
  margin-block-end: 10%;
}

.messageStatus {
  text-align: center;
  margin: auto;
  border: none;
}

.goBack {
  font-size: 5em;
  background-color: white;
}

/* Responsive Design */
@media (max-width: 1200px) {
  .main-page h1 {
    font-size: 2.5em;
  }

  .main-page h3 {
    font-size: 1.8em;
  }

  p {
    font-size: 1.3em;
  }

  .buttons {
    height: 25rem;
  }

  .buttons button {
    width: 90px;
    height: 90px;
  }
}

@media (max-width: 992px) {
  .main-page h1 {
    font-size: 2.2em;
  }

  .main-page h3 {
    font-size: 1.6em;
  }

  p {
    font-size: 1.2em;
  }

  .buttons {
    height: 20rem;
  }

  .buttons button {
    width: 80px;
    height: 80px;
  }

  #profile-photo {
    width: 80%;
  }
}

@media (max-width: 768px) {
  .main-page h1 {
    font-size: 2em;
  }

  .main-page h3 {
    font-size: 1.4em;
  }

  p {
    font-size: 1.1em;
  }

  .buttons {
    height: 15rem;
    border-inline-end: none; /* Remove border on small screens */
  }

  .buttons button {
    width: 70px;
    height: 70px;
    margin-block-start: 30%;
  }

  #profile-photo {
    width: 90%;
  }

  .skills {
    margin-top: 30px;
  }

  .skills h5 {
    margin-bottom: 10px;
  }

  .languages {
    margin-bottom: 20px;
  }

  .icons {
    margin: 1rem;
  }

  .fa, .fab, .fas, .far {
    font-size: 2.5em;
  }
}

@media (max-width: 576px) {
  .main-page h1 {
    font-size: 1.8em;
  }

  .main-page h3 {
    font-size: 1.2em;
  }

  p {
    font-size: 1em;
  }

  .buttons {
    height: 10rem;
  }

  .buttons button {
    width: 60px;
    height: 60px;
    margin-block-start: 20%;
  }

  #profile-photo {
    width: 100%;
  }

  .skills {
    margin-top: 20px;
  }

  .skills h5 {
    margin-bottom: 5px;
  }

  .languages {
    margin-bottom: 15px;
  }

  .icons {
    margin: 0.8rem;
  }

  .fa, .fab, .fas, .far {
    font-size: 2em;
  }

  #contactPage {
    width: 90%;
  }

  .goBack {
    font-size: 3em;
  }
}